import React from "react";

function Header() {
  return (
    <>
      <header>
        {/*<div className="header_content"><a className="header_nav" href='#example'></a> </div>
              <div className="header_content"><a className="header_nav" href='#example'></a></div>*/}
      </header>
    </>
  );
}

export default Header;
