import React from "react";

import video from "../../videos/LinguPingu.mp4";
import thumbnail from "../../images/Awd/GrammarHub.JPG";
import excitedPingu from "../../images/Awd/excitedPingu.png";
import "../../styles/projects.css";
import "../../styles/awd.css";
function Awd() {
  return (
    <div className="project-content-box">
      <div className="project-box">
        <div className="project-description">
          In Advanced Web Development (AWD) war es die Aufgabe, eine progressive
          Webanwendung zu entwickeln. Dabei war es auch wichtig
          backend-Technologien zu verwenden. Wir entschieden uns im Team für
          eine Sprachlernwebseite, die die Möglichkeit bietet, Vokabeln zu
          lernen und Grammatikübungen zu machen. Der Benutzer kann sich
          registrieren und anmelden. Die Webseite speichert die Daten in einer
          MongoDB Datenbank. Auch ist sie responsiv und kann auf allen Geräten
          genutzt werden.
          <br />
          <br />
          <div className="project-subheader">Was ist LinguPingu?</div>
          
          LinguPingu ist eine Sprachenlernapp, die das Vokabellernen zu einem
          unterhaltsamen und effektiven Erlebnis macht. Durch die Kombination
          von Vokabeltraining, interaktiven Lektionen und der Möglichkeit,
          individuelle Vokabelkollektionen zu erstellen, hebt sich LinguPingu
          deutlich von anderen Sprachenlern-Apps ab. Inspiriert von
          erfolgreichen Anwendungen wie Duolingo und Busuu, legt LinguPingu
          besonderen Wert auf die Integration von benutzererstellten
          Vokabelkollektionen. Dies ermöglicht es den Nutzern, ihr persönliches
          Lernumfeld zu gestalten und ihre individuellen Lernziele effizient zu
          verfolgen.
          <div className="project-image" >
          <figure >
            <img
              src={excitedPingu}
              alt="Beschriftung"
              className="img-awd"
            />
          </figure>
        </div>
          <div className="project-subheader">Grammatikübungen</div>
          Der Benutzer kann die Grammatik der gewünschten Sprache in zwei
          Hauptkategorien erlernen. Die Lektionen umfassen einerseits die
          verschiedenen Zeitformen, sodass der Benutzer jede Zeitform in der
          gewählten Sprache sicher beherrschen kann. Andererseits gibt es die
          Kategorie des täglichen Lebens, in der der Nutzer Situationen und
          Ausdrücke aus dem Alltag erlernt. Hierbei steht vor allem die
          Anwendung grammatikalisch korrekter Sätze im täglichen Umgang im
          Vordergrund. Beim Klick auf eine Lektion erscheint ein erklärender
          Text zur Grammatik. Anschließend kann der Benutzer die Übung starten,
          bei der er den dargestellten Satz in die zu lernende Sprache
          übersetzen muss, indem er vorgegebene Wörter auswählt. Die Übung
          enthält auch falsche Wörter, um die Schwierigkeit zu erhöhen. Um eine
          Antwort zu ändern oder einen Tippfehler zu korrigieren, kann der
          Benutzer einfach auf das entsprechende Wort klicken und es erneut
          auswählen. Nach der Bestätigung wird die Antwort überprüft. Wenn die
          Antwort falsch ist, wird die Karte wackeln und ein trauriger Pinguin
          erscheint. Die Antwort des Benutzers wird gelöscht und er muss den
          Satz erneut bilden. Wenn die Antwort richtig ist, wird die Karte grün
          umrandet und ein fröhlicher Pinguin erscheint. Die neue Aufgabe wird
          anschließend angezeigt und dies geschieht so lange, bis alle Aufgaben
          für die jeweilige Lektion erledigt sind. Danach erscheint ein Feld, um
          den Abschluss der Lektion zu bestätigen. Der Benutzer kann nun zur
          Grammatik-Seite zurückkehren. Die abgeschlossene Lektion wird mit
          grüner Hintergrundfarbe hervorgehoben. Jede abgeschlossene Lektion
          wird in der Datenbank gespeichert und ist für jeden Benutzer
          individuell.
        </div>
        <div className="project-subheader">Video</div>
        <div className="project-video">
          <video controls src={video} poster={thumbnail} />
        </div>
      </div>
    </div>
  );
}
export default Awd;
